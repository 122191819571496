var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pagination-wrapper"},[_c('VuePagination2',{staticClass:"pagination",class:{ 'disabled': _vm.disabled },attrs:{"page":_vm.currentPage,"records":_vm.totalCount,"per-page":_vm.currentLimit,"options":{
      chunk: _vm.pagesOnChunk,
      chunksNavigation: 'scroll',
      edgeNavigation: true,
      texts: {
        count: '',
        first: _vm.$t('page_first'),
        last: ((_vm.$t('page_last')) + ": " + _vm.pageCount)
      }
    }},on:{"paginate":_vm.currentPageChange}}),_vm._v(" "),_c('div',{staticClass:"pagination-setting"},[_c('Input',{staticClass:"pagination-setting__page",attrs:{"value":_vm.currentPage,"label":_vm.$t('page_go_to'),"min":1,"max":_vm.pageCount,"type":"number"},on:{"input":_vm.currentPageChangeDebounce}}),_vm._v(" "),_c('Select',{staticClass:"pagination-setting__limit",attrs:{"value":_vm.currentLimit,"options":_vm.limitOptions,"id":"limit","label":_vm.$t('page_record_number'),"no-empty-value":""},on:{"input":_vm.currentLimitChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }