<template>
  <DamList ref="damList" :is-pdf="true">
    <template #upload-button>
      <DamPdfUploadButton
        multiple
        @set-media="() => $refs.damList.addMedia()"
      />
    </template>
  </DamList>
</template>
<script>
import DamList from '@/components/dam/DamList'
import DamPdfUploadButton from '@/components/shared/DamPdfUploadButton'
export default {
  name: 'DamPdfList',
  components: { DamPdfUploadButton, DamList }
}
</script>
