<template>
  <Modal disable-header >
    <template slot="body">
      <ModuleForm
        :module-name="assetInfoText"
        :show-header-info="false"
        :tabs="tabs"
        @tab-change="(index) => tabIndex = index"
      >
        <template #form-title>
          <IconInfo class="form-icon" />
          {{ assetInfoText }}
        </template>
        <template #tab-title="{ tab, index }">
          <component
            :is="getIconComponentName(tab)"
            class="tab-icon"
            :class="{ 'tab-active': index === tabIndex }"
          />
          {{ tab.name }}
        </template>
        <template #header-buttons>
          <AssetInfoForm
            v-show="tabIndex === 0"
            :asset="asset"
            @close="$emit('close')"
            header
          />
          <AssetEditForm
            v-show="tabIndex === 1"
            :asset="asset"
            @close="$emit('close')"
            @post-update="$emit('post-update', $event)"
            header
          />
          <AssetCropForm
            v-if="!isPdf"
            v-show="tabIndex === 2"
            :asset="asset"
            @rotate-left="() => $refs.assetCropForm.rotate(270)"
            @rotate-right="() => $refs.assetCropForm.rotate(90)"
            @face-detect="() => $refs.assetCropForm.faceDetect()"
            @save-crop="() => $refs.assetCropForm.saveCrop()"
            @close="$emit('close')"
            header
          />
          <button
            type="button"
            class="close"
            @click="$emit('close')"
          >
            ×
          </button>
        </template>
      <template #form-content>
        <AssetCropForm
          v-show="tabIndex === 2"
          :asset="asset"
          ref="assetCropForm"
          class="form-content asset-crop"
          @close="$emit('close')"
          @post-update="$emit('post-update', $event)"
        />
        <div v-show="tabIndex !== 2" class="form-content">
          <div class="form-content__left">
            <AssetInfoForm
              v-show="tabIndex === 0"
              :asset="asset"
              @close="$emit('close')"
              left
            />
            <AssetEditForm
              v-show="tabIndex === 1"
              :asset="asset"
              @close="$emit('close')"
              left
            />
          </div>
          <div class="form-content__separator">
            <div class="form-content__separator__line"/>
          </div>
          <div class="form-content__right">
            <AssetInfoForm
              class="info-form-right"
              v-show="tabIndex === 0"
              :asset="asset"
              @close="$emit('close')"
              right
            />
            <AssetEditForm
              v-show="tabIndex === 1"
              :asset="asset"
              @close="$emit('close')"
              right />
          </div>
        </div>
        </template>
      </ModuleForm>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex'
import Modal from '@/components/shared/Modal'
import ModuleForm from '@/components/shared/ModuleForm'
import AssetInfoForm from '@/components/dam/assetDetail/AssetInfoForm'
import AssetCropForm from '@/components/dam/assetDetail/AssetCropForm'
import AssetEditForm from '@/components/dam/assetDetail/AssetEditForm'
import IconInfo from '@/assets/img/svg/info.svg?inline'
import IconFile from '@/assets/img/svg/file.svg?inline'
import IconEdit from '@/assets/img/svg/edit.svg?inline'
import IconCrop from '@/assets/img/svg/crop.svg?inline'

export default {
  name: 'AssetDetailModal',
  props: {
    asset: {
      type: Object
    }
  },
  components: {
    IconInfo,
    IconFile,
    IconEdit,
    IconCrop,
    AssetEditForm,
    AssetCropForm,
    AssetInfoForm,
    ModuleForm,
    Modal
  },
  computed: {
    ...mapGetters('dam', [
      'isPdf'
    ]),
    assetInfoText () {
      return this.isPdf ? this.$t('dam.asset_pdf_info') : this.$t('dam.asset_image_info')
    },
    tabs () {
      return [
        { name: this.$t('dam.info_modal_header'), icon: 'file' },
        { name: this.$t('dam.update_asset_metadata'), icon: 'edit' },
        ...this.isPdf ? [] : [{ name: this.$t('dam.crop_modal_header'), icon: 'crop' }]
      ]
    }
  },
  data () {
    return {
      tabIndex: 0
    }
  },
  methods: {
    getIconComponentName (tab) {
      const iconName = tab.icon.charAt(0).toUpperCase() + tab.icon.slice(1)
      return `Icon${iconName}`
    }
  }
}
</script>

<style lang="scss" scoped>
.close {
  margin-left: 1rem;
}
.asset-crop {
  justify-content: center;
}
.form-icon {
  @include size(20px);
  fill: #9DAAC4;
}
.tab-icon {
  @include size(16px);
  fill: #9DAAC4;
  padding-top: rem(3px);
}
.tab-active {
  fill: #6599FE;
}
.info-form-right {
  max-width: 99%;
}
</style>
