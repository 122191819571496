<template>
  <div>
    <template v-if="header">
      <button
        type="button"
        class="form-button"
        :class="{ 'form-button--disabled' : callingAPI }"
        :disabled="callingAPI"
        @click="save"
      >
        <Preloader v-if="callingAPI" class="save-button__loader" />
        <i v-else class="fa fa-save" />
        {{ $t('buttons.save') }}
      </button>
    </template>
    <template v-else>
      <div v-if="left">
        <app-input
          v-model="asset.metadata.headline"
          id="asset_metadata_headline"
          :label="$t('dam.headline')"
        >
          <app-tooltip
            :title="$t('dam.headline_seo_info')"
            icon="fab fa-google"
            customInfoClass="seo"
          ></app-tooltip>
        </app-input>
        <app-input
          v-model="asset.metadata.byLine"
          id="asset_metadata_byLine"
          :label="$t('dam.by_line')"
        >
        </app-input>
        <app-input
          v-model="asset.metadata.caption"
          id="asset_metadata_caption"
          :label="$t('dam.caption')"
        >
        </app-input>
        <app-input
          v-model="asset.metadata.captionWriter"
          id="asset_metadata_captionWriter"
          :label="$t('dam.caption_writer')"
        >
        </app-input>
        <app-input
          v-model="asset.metadata.keywords"
          id="asset_metadata_keywords"
          :label="$t('dam.keywords')"
        >
        </app-input>
        <app-textarea
          v-model="asset.metadata.description"
          id="asset_metadata_description"
          :label="$t('dam.description')"
        >
        </app-textarea>
      </div>
      <div v-if="right">
        <div v-if="asset.metadata.category === undefined">
          <app-input
            v-model="asset.metadata.city"
            id="asset_metadata_city"
            :label="$t('dam.city')"
          >
          </app-input>
          <app-input
            v-model="asset.metadata.country"
            id="asset_metadata_country"
            :label="$t('dam.country')"
          >
          </app-input>
          <app-input
            v-model="asset.metadata.countryCode"
            id="asset_metadata_countryCode"
            :label="$t('dam.country_code')"
          >
          </app-input>
        </div>
        <app-input
          v-model="asset.metadata.credit"
          id="asset_metadata_credit"
          :label="$t('dam.credit')"
        >
        </app-input>
        <app-input
          v-if="asset.metadata.personInImage !== undefined"
          :value="asset.metadata.personInImage ? asset.metadata.personInImage.toString() : ''"
          @input="(value) => asset.metadata.personInImage = value ? value.split(',').map(v => v.trim()) : []"
          id="asset_metadata_personInImage"
          :label="$t('dam.person_in_image')"
        >
        </app-input>
        <app-input
          v-model="asset.metadata.rights"
          id="asset_metadata_rights"
          :label="$t('dam.rights')"
        >
        </app-input>
        <app-input
          v-model="asset.metadata.source"
          id="asset_metadata_source"
          :label="$t('dam.source')"
        >
        </app-input>
        <app-select
          v-if="asset.metadata.category !== undefined"
          v-model="asset.metadata.category"
          id="asset_metadata_category"
          :label="$t('dam.category')"
          :options="damCategories"
          track-by="id"
          no-empty-value
        >
        </app-select>
        <div v-if="showRestrictedSitesInput">
          <label>
            {{ $t('dam.restricted_sites') }}
            <app-tooltip :title="getAllEnabledSiteNames"></app-tooltip>
          </label><br>
          <app-input
            :value="asset.metadata.transmissionReference ? asset.metadata.transmissionReference.toString() : ''"
            @input="(value) => asset.metadata.transmissionReference = value ? value.split(',').map(v => v.trim()) : []"
            id="asset_metadata_transmissionReference"
            no-label
          >
          </app-input>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Input from '../../form/inputs/Input'
import Textarea from '../../form/Textarea'
import NotifyService from '../../../services/NotifyService'
import Select from '../../form/select/Select'
import Tooltip from '../../tooltip/Tooltip'
import DamPdfCategoryMixin from '../../mixins/valueObject/DamPdfCategoryMixin'
import Preloader from '@/components/preloader/Preloader'
import { mapState } from 'vuex'

export default {
  name: 'AssetEditForm',
  mixins: [DamPdfCategoryMixin],
  props: {
    asset: {
      type: Object
    },
    header: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Preloader,
    appInput: Input,
    appTextarea: Textarea,
    appSelect: Select,
    appTooltip: Tooltip
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    showRestrictedSitesInput () {
      return this.asset.metadata.category === undefined && (
        this.$store.getters['user/hasRole']('ROLE_SUPER_ADMIN') ||
        this.$store.getters['user/hasRole']('ROLE_DAM_ADMIN')
      )
    },
    getAllEnabledSiteNames () {
      return this.$store.getters['site/all'].filter(site => site.enabled === true).map(function (site) {
        return site.name
      }).join('\n')
    }
  },
  methods: {
    async save () {
      this.$store.dispatch('dam/updateMetadata', this.asset)
        .then(() => {
          if (this.$store.getters['dam/error'] === null) {
            NotifyService.setSuccessMessage('Asset metadata was updated.')
            this.$emit('post-update')
            this.$emit('close')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['dam/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  mounted () {
    this.asset.metadata.transmissionReference = this.asset.restrictedSites
      .concat(this.asset.metadata.transmissionReference)
      .filter((elem, pos, arr) => {
        return arr.indexOf(elem) === pos && elem !== 'none'
      })
  }
}
</script>

<style scoped lang="scss">
.form-button {
  @include font(700 13px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #6599FE;
  color: #FFFFFF;
  height: rem(30px);
  width: rem(100px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(5px);
  position: relative;
  &:hover {
    background-color: lighten(#6599FE, 5%);
  }
  &--disabled {
    background-color: #D1DBE4;
    cursor: default;
    &:hover {
      background-color: #D1DBE4;
    }
  }
}
.save-button {
  &__loader {
    position: absolute;
    transform: scale(0.5);
    left: 0;
  }

}
</style>
