<template>
    <span>
        <button class="btn btn-info dam-upload-btn" @click="$refs.galleryFile.click()">
            <i class="fas fa-upload"></i>
            {{ $t('buttons.upload_from_computer') }}
        </button>
        <input
          type="file"
          ref="galleryFile"
          style="display: none"
          @change="readFilesOnInputChange"
          :multiple="multiple"
          accept="application/pdf, application/x-pdf"
          data-test="doc-upload"
        >
        <app-dam-pdf-upload-modal
          v-if="damPdfUploadModal"
          :files="uploadedFiles"
          :count="count"
          @close="closeDamPdfUploadModal"
          @set-media="setMedia"
        >
        </app-dam-pdf-upload-modal>
    </span>
</template>

<script>
import DamPdfUploadModal from '../dam/DamPdfUploadModal'
import DamApi from '../../api/dam'
import NotifyService from '../../services/NotifyService'
import Config from '../../config/index'
import MediaService from '../../services/media/MediaService'

export default {
  name: 'DamPdfUploadButton',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      dataLoaded: false,
      damPdfUploadModal: false,
      uploadedFiles: [],
      count: 0,
      timeoutError: false
    }
  },
  components: {
    appDamPdfUploadModal: DamPdfUploadModal
  },
  methods: {
    readFilesOnInputChange (event) {
      this.readFiles(event.target.files)
    },
    async readFiles (files) {
      this.timeoutError = false
      this.uploadedFiles = []
      this.loading = true
      this.count = files.length
      this.showDamPdfUploadModal()
      await Promise.all(
        Array.from(files).map((file) => this.extractIptc(file))
      )
      // Clear input file value, prevent to open same file
      this.$refs.galleryFile.value = ''
    },
    extractIptc (pdf) {
      const bodyFormData = new FormData()
      bodyFormData.set('pdf', pdf, pdf.name)
      return DamApi(Config.dam.apiExtractTimeout).post('/metadata/pdf', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(async (response) => {
          const uploadedFile = {
            pdf: pdf,
            headline: response.data.headline || pdf.name,
            byLine: response.data.byLine,
            keywords: response.data.keywords,
            base64: await MediaService.toBase64(pdf)
          }
          this.uploadedFiles.push(uploadedFile)
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && this.timeoutError === false) {
            this.timeoutError = true
            NotifyService.setErrorMessage(this.$t('notify.extract_timeout_error'), '', -1)
          }
          console.log(error)
        })
    },
    showDamPdfUploadModal () {
      this.damPdfUploadModal = true
    },
    closeDamPdfUploadModal () {
      this.damPdfUploadModal = false
    },
    setMedia (assets) {
      this.$emit('set-media', assets)
    },
    fileDragHover (e) {
      e.stopPropagation()
      e.preventDefault()
      var fileDrag = document.getElementById('filedrag')
      var dropZone = document.getElementById('drop-zone')
      if (e.type === 'dragover') {
        fileDrag.classList.add('dragover')
        dropZone.classList.add('active')
      } else {
        fileDrag.classList.remove('dragover')
        dropZone.classList.remove('active')
      }
    },
    fileSelectHandler (e) {
      this.fileDragHover(e)
      var files = e.target.files || e.dataTransfer.files
      this.readFiles(files)
    }
  },
  mounted () {
    var filedrag = document.getElementById('filedrag')
    var dropZone = document.getElementById('drop-zone')
    // Check if filedrag and dropZone is defined
    if (filedrag === null || dropZone === null) {
      return
    }
    filedrag.addEventListener('dragover', this.fileDragHover, false)
    dropZone.addEventListener('dragover', this.fileDragHover, false)
    dropZone.addEventListener('dragleave', this.fileDragHover, false)
    dropZone.addEventListener('drop', this.fileSelectHandler, false)
  }
}
</script>

<style lang="scss" scoped>

</style>
