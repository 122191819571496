<template>
  <app-modal
    :title="$t('dam.delete_modal_header')"
    modal-size="modal-md"
    closeOnClickSelf
    @close="close"
    data-test="dam_delete_modal"
  >
    <!-- Body -->
    <template slot="body">
      <img :src="previewImage" width="400">
      <h4 class="m-t-20">{{ $t('dam.article_reference') }}</h4>
      <app-asset-article-reference :asset="asset" />
    </template>

    <!-- Footer -->
    <template slot="footer">
      <button
        type="button"
        class="btn btn-inverse router-link-active"
        @click="close"
        data-test="hp_item_delete_cancel"
      >
        {{ $t('dam.delete_modal_close') }}
      </button>
      <button
        type="button"
        class="btn btn-danger"
        @click="deleteRecord"
        data-test="hp_item_delete_confirm"
      >
        {{ $t('dam.delete_modal_yes') }}
      </button>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'
import NotifyService from '../../services/NotifyService'
import MediaMixin from '../mixins/Media'
import DamPdfService from '../../services/dam/DamPdfService'
import DamService from '../../services/dam/DamService'
import AssetArticleReference from './AssetArticleReference'

export default {
  name: 'AssetDeleteModal',
  mixins: [MediaMixin],
  props: {
    asset: {
      type: Object
    }
  },
  components: {
    appModal: Modal,
    appAssetArticleReference: AssetArticleReference
  },
  computed: {
    previewImage () {
      if (this.asset.metadata.category === undefined) {
        return DamService.getImagePath(this.asset.basename)
      }
      return DamPdfService.getPdfPreviewPath(this.asset.basename)
    }
  },
  methods: {
    deleteRecord () {
      this.$store.dispatch('dam/deleteRecord', this.asset.id)
        .then(() => {
          if (this.$store.getters['dam/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$emit('post-delete')
            this.$emit('close')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['dam/error'])
          }
        })
        .catch(error => console.log(error))
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>
