<template>
  <section>
    <div class="dam-media-list">
      <div
        class="dam-media-list__col"
        :class="{'dam-media-list__col-tiny': !showMeta }"
        v-for="(asset, index) in assets" :key="`item-${index}`" :index="asset.id"
      >
        <div
          class="media-item"
          :class="{'media-item-is-selectable': selectionMode, 'media-item-selected': isSelected(asset)}"
          @click="toggleSelection(asset)"
        >
          <div
            @click="showAssetDetailModal(asset)"
            class="media-item__image"
            :style="`background-image: url(${(isPdf ? getDamPdfPreviewPath : getDamImagePath)(asset.basename)})`"
          >
            <template v-if="asset.restrictedSites[0] !== 'none'">
              <span
                class="media-item__label media-item__label--restricted-site"
                v-for="(restrictedSite, index) in asset.restrictedSites"
                :key="`item-${index}`"
              >
                {{ getFullSiteTitle(restrictedSite) }}
              </span>
            </template>
            <span
              class="media-item__label media-item__label--user"
              v-if="getPredefinedDamUser(asset)"
            >
              {{ getPredefinedDamUser(asset) }}
            </span>
            <span>
              <CheckIcon
                v-if="isSelected(asset)"
                class="checkbox-selected"
              />
            </span>
          </div>
          <div class="meta-info" v-if="showMeta">
            <small
              v-for="(metaInfo, index) in getMetaInfo(asset)"
              :key="`asset-${asset.id}-metainfo-${index}`"
              class="crop-long-text"
            >
              <span class="meta-info__label"> {{ metaInfo.label }}: </span>
              <span class="meta-info__value" :title="metaInfo.value"> {{ metaInfo.value }} </span>
            </small>
          </div>
          <div
            class="item-buttons"
            :class="{'show-meta-on-hover': showMeta === false}"
            v-if="selectionMode === false"
          >
            <button
              class="item-buttons__button item-buttons__button--detail"
              @click="showAssetDetailModal(asset)"
            >
              <IconInfo class="item-buttons__button__icon" />
              {{ assetInfoText }}
            </button>
            <button
              target="_blank"
              class="item-buttons__button item-buttons__button--download"
              @click="downloadAsset(asset)"
            >
              <i class="fas fa-download"></i>
              {{ $t('media.button_download') }}
            </button>
            <button
              v-if="isPdf"
              class="item-buttons__button"
              @click="originalUrlToClipboard(asset)"
            >
              <i class="fas fa-link"></i>
            </button>
            <button
              class="item-buttons__button item-buttons__button--delete"
              @click="showAssetDeleteModal(asset)"
              data-test="delete_image"
            >
              <IconDelete class="item-buttons__button--delete__icon" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <AssetDetailModal
      v-if="assetDetailModal"
      :asset="selectedAsset"
      @close="closeAssetDetailModal"
      @post-update="searchAssets"
    />
    <AssetDeleteModal
      v-if="assetDeleteModal"
      :asset="selectedAsset"
      @post-delete="searchAssets"
      @close="closeAssetDeleteModal"
    />
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MediaMixin from '@/components/mixins/Media'
import { PREDEFINED_DAM_USERS } from '@/services/dam/DamService'
import CheckIcon from '@/assets/img/svg/check.svg?inline'
import AssetDetailModal from './assetDetail/AssetDetailModal'
import AssetDeleteModal from './AssetDeleteModal'
import IconInfo from '@/assets/img/svg/info.svg?inline'
import IconDelete from '@/assets/img/svg/delete.svg?inline'

export default {
  name: 'DamListTable',
  mixins: [MediaMixin],
  data () {
    return {
      assetDetailModal: false,
      assetDeleteModal: false,
      selectedAsset: {}
    }
  },
  props: {
    assets: {
      type: Array,
      required: true
    },
    selectedAssets: {
      type: Object,
      required: true
    },
    selectionMode: {
      type: Boolean,
      required: true
    },
    showMeta: {
      type: Boolean,
      default: true
    },
    maxCountOfSelectedAssets: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    ...mapGetters('dam', [
      'isPdf'
    ]),
    assetInfoText () {
      return this.isPdf ? this.$t('dam.asset_pdf_info') : this.$t('dam.asset_image_info')
    },
    getAllSites () {
      return this.$store.getters['site/all']
    },
    selectedCount () {
      return Object.keys(this.selectedAssets).length ?? 0
    }
  },
  components: {
    IconDelete,
    IconInfo,
    CheckIcon,
    AssetDetailModal,
    AssetDeleteModal
  },
  methods: {
    searchAssets () {
      this.$store.dispatch('dam/fetchPage')
    },
    showAssetDetailModal (asset) {
      this.selectedAsset = asset
      this.assetDetailModal = true
    },
    closeAssetDetailModal () {
      this.assetDetailModal = false
    },
    showAssetDeleteModal (asset) {
      this.selectedAsset = asset
      this.assetDeleteModal = true
    },
    closeAssetDeleteModal () {
      this.assetDeleteModal = false
    },
    getSize (asset) {
      const size = Math.round(asset.size * 1000 / 1048576) / 1000 + ' MB'
      if (this.isPdf) {
        return size
      }
      return `${asset.dimensions?.width} x ${asset.dimensions?.height} px, ${size}`
    },
    prettyDate (dateTime) {
      return this.$options.filters.prettyDateTime(dateTime)
    },
    getMetaInfo (asset) {
      const result = [
        { label: this.$t('dam.id'), value: asset.id }
      ]
      if (asset.metadata.headline) {
        result.push({ label: this.$t('dam.headline'), value: asset.metadata.headline })
      } else {
        result.push({ label: this.$t('dam.description'), value: asset.metadata.description })
      }
      const createdValue = `${this.prettyDate(asset.createdAt)}, ${asset.createdBy}`
      result.push(...[
        { label: this.$t('dam.by_line'), value: asset.metadata.byLine },
        { label: this.$t('dam.size'), value: this.getSize(asset) },
        { label: this.$t('dam.created'), value: createdValue },
        {
          label: this.$t(`dam.datetime_original${this.pdf ? '_pdf' : ''}`),
          value: this.prettyDate(asset.metadata.dateTimeOriginal)
        }
      ])
      return result
    },
    getFullSiteTitle (siteName) {
      return this.getAllSites.find(site => site.name === siteName)?.title
    },
    getPredefinedDamUser (asset) {
      return PREDEFINED_DAM_USERS[asset.createdBy]
    },
    isSelected (asset) {
      return this.selectedAssets[asset.id]
    },
    toggleSelection (asset) {
      if (!this.selectionMode) {
        return
      }
      const selectedAssets = { ...this.selectedAssets }
      if (this.isSelected(asset)) {
        delete selectedAssets[asset.id]
      } else if (this.selectedCount < this.maxCountOfSelectedAssets) {
        selectedAssets[asset.id] = asset
      }
      this.$emit('selected-assets-change', selectedAssets)
    },
    cancelSelection () {
      this.$emit('selected-assets-change', {})
    }
  }
}
</script>

<style lang="scss" scoped>
.meta-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
  small {
    flex: 0 0 100%;
  }
  &__label {
    @include font(400 12px "Roboto");
    color: #979797;
  }
  &__value {
    @include font(800 12px "Roboto");
    color: #465674;
  }
}

.crop-long-text {
  white-space: nowrap;
  overflow: hidden;
  float: left;
  text-overflow: ellipsis;
}

.show-meta-on-hover {
  width: 100%;
  display: none;
  position: absolute;
  text-align: center;
  z-index: 10;
  bottom: 20px;
  left: 0;
}

.media-item {
  background-color: #FFFFFF;
  border: rem(1px) solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 0.7rem;
  margin-bottom: 0.7rem;
  position: relative;
  &:hover {
    .show-meta-on-hover {
      display: block;
    }
  }
  &__image {
    width: 100%;
    height: rem(220px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.125);
    cursor: zoom-in;
    position: relative;
    margin-bottom: rem(5px);
  }
  &__label {
    float: right;
    padding: rem(2px) rem(7px);
    margin-left: rem(4px);
    @include font(400 12px "Roboto");
    color: #FFFFFF;
    line-height: rem(15px);
    border-radius: rem(6px);
    &--restricted-site {
      background-color: #fc4b6c;
    }
    &--user {
      background-color: #6599FE;
    }
  }
  &.media-item-is-selectable {
    cursor: pointer;

    * {
      pointer-events: none;
    }
  }

  &.media-item-selected {
    border: 2px solid #5AB963;
    box-shadow: 1px 1px 4px rgba(30, 136, 229, 0.4);
  }
}
.dam-media-list > div {
  padding-left: rem(5px);
  padding-right: rem(5px);
}

.dam-media-list {
  display: flex;
  flex-wrap: wrap;
  &__col {
    @include bp(0 10) {
      flex: 0 0 50%;
      max-width: 50%;
      .crop-long-text {
        // prevents long text from stretching out the whole col
        max-width: calc(100vw / 2);
      }
    }
    @include bp(10 12) {
      flex: 0 0 33.3%;
      max-width: 33.3%;
      .crop-long-text {
        // prevents long text from stretching out the whole col
        max-width: calc(100vw / 3);
      }
    }
    @include bp(12) {
      flex: 0 0 25%;
      max-width: 25%;
      .crop-long-text {
        // prevents long text from stretching out the whole col
        max-width: calc(100vw / 4);
      }
    }
  }
  &__col-tiny {
    @include bp(12) {
      flex: 0 0 16.6%;
      max-width: 16.6%;
    }
  }
}

.checkbox-selected {
  @include size(22px);
  position: absolute;
  bottom: rem(10px);
  right: rem(10px);
  path:first-of-type {
    fill: #5AB963;
  }
  path:nth-of-type(2n){
    fill: #ffffff;
  }
}

.item-buttons {
  &:not(.show-meta-on-hover) {
    display: flex;
    gap: rem(4px);

    .item-buttons__button--detail, .item-buttons__button--download {
      width: 100%;
    }
  }
  &__button {
    @include font(700 13px "Roboto");
    border-radius: rem(6px);
    border: 1px solid #D1DBE4;
    cursor: pointer;
    background-color: #FFFFFF;
    color: #465674;
    height: rem(30px);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: rem(5px);
    &:hover {
      background-color: lighten(#D1DBE4, 5%);
    }
    &__icon {
      @include size(12px);
      fill: #9DAAC4;
    }
    &--detail, &--download {
      padding-left: rem(10px);
      padding-right: rem(10px);
    }
    &--download {
      i {
        color: #9DAAC4;
      }
    }
    &--delete {
      background-color: #E9596F;
      border: 1px solid #E9596F;
      &:hover {
        background-color: lighten(#E9596F, 5%);
      }
      &__icon {
        @include size(12px);
        fill: #FFFFFF;
      }
    }
  }
}
</style>
