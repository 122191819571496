<template>
  <div>
    <div v-if="header" class="form-buttons">
      <button
        type="button"
        class="form-button form-button--download"
        @click="downloadAsset(asset)"
        :title="$t('dam.download_original')"
      >
        <IconDownload class="form-button__icon" />
        {{ $t('dam.download_original') }}
      </button>
      <button
        v-if="isPdf"
        class="form-button"
        @click="originalUrlToClipboard(asset)"
      >
        <i class="fas fa-link"></i>
      </button>
    </div>
    <div v-else class="asset-info">
      <div v-show="left">
        <a :href="isImageAsset === false && getOriginalUrl(asset)" target="_blank">
          <div
            class="asset-info-image"
            :style="'background-image: url(' + previewImage + ')'"
          >
          </div>
        </a>
      </div>
      <div v-show="right">
        <h4>{{ $t('dam.system_info') }}</h4>
        <table class="table table-striped asset-info-table">
          <tr>
            <th width="150">{{ $t('dam.id') }}</th>
            <td>{{ asset.id }}</td>
          </tr>
          <tr>
            <th>{{ $t('dam.filename') }}</th>
            <td>{{ asset.filename }}</td>
          </tr>
          <tr>
            <th>{{ $t('dam.created') }}</th>
            <td>{{ asset.createdAt | prettyDateTime }} by {{ asset.createdBy }}</td>
          </tr>
          <tr>
            <th>{{ $t('dam.upload_source') }}</th>
            <td><span class="label label-success">{{ asset.uploadSource }}</span></td>
          </tr>
          <tr v-if="asset.restrictedSites[0] !== 'none'">
            <th>{{ $t('dam.restricted_sites') }}</th>
            <td>
                              <span
                                class="label label-danger m-r-5"
                                v-for="(restrictedSite, index) in asset.restrictedSites"
                                :key="`item-${index}`"
                              >
                                  {{ getFullSiteTitle(restrictedSite) }}
                              </span>
            </td>
          </tr>
          <tr>
            <th>{{ $t('dam.size') }}</th>
            <td>{{ getAssetSize(asset) }}</td>
          </tr>
          <tr v-if="isImageAsset">
            <th>{{ $t('dam.dimensions') }}</th>
            <td>{{ getAssetDimensions(asset) }}</td>
          </tr>
          <tr v-else>
            <th>{{ $t('dam.content') }}</th>
            <td v-if="content">
              <textarea v-model="content" disabled class="asset-content"></textarea>
            </td>
            <td v-else>
              <button
                target="_blank"
                class="btn btn-sm btn-danger"
                @click="loadContent(asset)"
              >
                <i class="fas fa-download"></i> <span class="hidden-md-down">{{ $t('dam.content_load') }}</span>
              </button>
            </td>
          </tr>
        </table>

        <h4 class="m-t-20">{{ $t('dam.metadata') }}</h4>
        <table class="table table-striped asset-info-table">
          <tr v-if="asset.metadata.headline">
            <th width="150">{{ $t('dam.headline') }}:</th>
            <td>{{ asset.metadata.headline }}</td>
          </tr>
          <tr v-if="asset.metadata.byLine">
            <th>{{ $t('dam.by_line') }}</th>
            <td>{{ asset.metadata.byLine }}</td>
          </tr>
          <tr>
            <th>{{ $t('dam.keywords') }}</th>
            <td>
                              <span
                                class="label label-success m-r-5 metadata-keyword"
                                v-for="(keyword, index) in asset.metadata.keywords"
                                :key="`item-${index}`"
                                @click="filterByKeyword(keyword)"
                              >
                                {{ keyword }}
                              </span>
            </td>
          </tr>
          <tr v-if="asset.metadata.description">
            <th>{{ $t('dam.description') }}</th>
            <td>{{ asset.metadata.description }}</td>
          </tr>
          <tr v-if="asset.metadata.caption">
            <th>{{ $t('dam.caption') }}</th>
            <td>{{ asset.metadata.caption }}</td>
          </tr>
          <tr v-if="asset.metadata.captionWriter">
            <th>{{ $t('dam.caption_writer') }}</th>
            <td>{{ asset.metadata.captionWriter }}</td>
          </tr>
          <tr v-if="isImageAsset && asset.metadata.city">
            <th>{{ $t('dam.city') }}</th>
            <td>{{ asset.metadata.city }}</td>
          </tr>
          <tr v-if="isImageAsset && asset.metadata.country">
            <th>{{ $t('dam.country') }}</th>
            <td>{{ asset.metadata.country }}</td>
          </tr>
          <tr v-if="isImageAsset && asset.metadata.countryCode">
            <th>{{ $t('dam.country_code') }}</th>
            <td>{{ asset.metadata.countryCode }}</td>
          </tr>
          <tr v-if="asset.metadata.credit">
            <th>{{ $t('dam.credit') }}</th>
            <td>{{ asset.metadata.credit }}</td>
          </tr>
          <tr v-if="asset.metadata.dateTimeOriginal">
            <th>{{ $t('dam.datetime_original') }}</th>
            <td>{{ asset.metadata.dateTimeOriginal | prettyDateTime }}</td>
          </tr>
          <tr v-if="asset.metadata.category">
            <th>{{ $t('dam.category') }}</th>
            <td class="label label-success">{{ pdfCategory }}</td>
          </tr>
          <tr v-if="asset.metadata.locationShow ">
            <th>{{ $t('dam.location_show') }}</th>
            <td>{{ asset.metadata.locationShow }}</td>
          </tr>
          <tr v-if="isImageAsset && asset.metadata.personInImage && asset.metadata.personInImage.length > 0">
            <th>{{ $t('dam.person_in_image') }}</th>
            <td>
              <template v-for="(person, index) in asset.metadata.personInImage">
                <span :key="`item-${index}`">{{ person }}</span>
              </template>
            </td>
          </tr>
          <tr v-if="asset.metadata.rights">
            <th>{{ $t('dam.rights') }}</th>
            <td>{{ asset.metadata.rights }}</td>
          </tr>
          <tr v-if="asset.metadata.source">
            <th>{{ $t('dam.source') }}</th>
            <td>{{ asset.metadata.source }}</td>
          </tr>
        </table>
        <div>
          <h4 class="m-t-20">{{ $t('media.actions') }}</h4>
          <button
            v-if="isImageAsset === false"
            class="btn btn-sm btn-inverse"
            @click="originalUrlToClipboard(asset)"
          >
            <i class="fas fa-link"></i>
          </button>
          <button
            target="_blank"
            class="btn btn-sm btn-inverse"
            @click="downloadAsset(asset)"
          >
            <i class="fas fa-download"></i> <span class="hidden-md-down">{{ $t('media.button_download') }}</span>
          </button>
          <a
            v-if="isImageAsset === false"
            :href="getOriginalUrl(asset)"
            target="_blank"
            class="btn btn-sm btn-inverse"
          >
            <i class="fas fa-eye"></i> <span class="hidden-md-down">{{ $t('media.button_open_preview') }}</span>
          </a>
        </div>
        <div>
          <h4 class="m-t-20">{{ $t('dam.article_reference') }}</h4>
          <app-asset-article-reference :asset="asset" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaMixin from '../../mixins/Media'
import DamPdfService from '../../../services/dam/DamPdfService'
import DamApi from '../../../api/dam'
import DamService from '../../../services/dam/DamService'
import DamPdfCategoryMixin from '../../mixins/valueObject/DamPdfCategoryMixin'
import NotifyService from '../../../services/NotifyService'
import AssetArticleReference from '../AssetArticleReference'
import IconDownload from '@/assets/img/svg/download.svg?inline'
import { mapGetters } from 'vuex'

export default {
  name: 'AssetInfoForm',
  mixins: [MediaMixin, DamPdfCategoryMixin],
  props: {
    asset: {
      type: Object,
      required: true
    },
    header: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  components: {
    appAssetArticleReference: AssetArticleReference,
    IconDownload
  },
  computed: {
    ...mapGetters('dam', [
      'isPdf'
    ]),
    pdfCategory () {
      try {
        return this.damCategories.filter((i) => i.id === this.asset.metadata.category)[0].title
      } catch (err) {
        return this.damCategories[0].title
      }
    },
    getAllSites () {
      return this.$store.getters['site/all']
    },
    previewImage () {
      if (this.asset.metadata.category === undefined) {
        return DamService.getImagePath(this.asset.basename)
      }
      return DamPdfService.getPdfPreviewPath(this.asset.basename)
    },
    isImageAsset () {
      return this.asset.metadata.category === undefined
    }
  },
  data () {
    return {
      content: ''
    }
  },
  methods: {
    filterByKeyword (keyword) {
      const filter = this.$store.getters['dam/filter']
      filter.keywords = keyword
      this.$store.commit('dam/setFilter', filter)
      this.$store.dispatch('dam/fetchPage')
      this.$emit('close')
    },
    async loadContent (asset) {
      const result = await DamApi().get('/pdf/content/' + asset.id)
      this.content = result.data
    },
    async originalUrlToClipboard (asset) {
      await navigator.clipboard.writeText(this.getOriginalUrl(asset))
      NotifyService.setInfoMessage(this.$i18n.t('notify.url_was_copied'))
    },
    getOriginalUrl (asset) {
      return DamPdfService.getPdfPath(asset.basename)
    },
    getPreviewUrl (asset) {
      return DamPdfService.getPdfPreviewPath(asset.basename)
    },
    getAssetSize (asset) {
      return asset.size ? Math.round(asset.size * 1000 / 1048576) / 1000 + ' MB' : ''
    },
    getAssetDimensions (asset) {
      return asset.dimensions ? asset.dimensions.width + ' x ' + asset.dimensions.height + ' px' : ''
    },
    getFullSiteTitle (siteName) {
      return this.getAllSites.find(site => site.name === siteName).title
    }
  }
}
</script>

<style lang="scss" scoped>
.asset-info-image {
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
  border: none;
  cursor: pointer;
  position: relative;
  margin-top: 30px;
}

.asset-info-table {
  font-size: 14px;

  span {
    font-size: 13px;
  }
}

.asset-content {
  border: 0;
  background: none;
  width: 100%;
  height: 20ex;
}

.form-buttons {
  display: flex;
  gap: rem(4px);
}
.form-button {
  @include font(700 13px "Roboto");
  border-radius: rem(6px);
  border: 1px solid #D1DBE4;
  cursor: pointer;
  background-color: #FFFFFF;
  color: #465674;
  height: rem(30px);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: rem(5px);
  &:hover {
    background-color: lighten(#D1DBE4, 5%);
  }
  &--download {
    width: rem(150px);
  }
  &__icon {
    @include size(14px);
    fill: #9DAAC4;
  }
}
.metadata-keyword {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    background-color: darken(#26c6da, 20%);
  }
}
</style>
