<template>
  <div class="pagination-wrapper">
    <VuePagination2
      class="pagination"
      :class="{ 'disabled': disabled }"
      :page="currentPage"
      :records="totalCount"
      :per-page="currentLimit"
      @paginate="currentPageChange"
      :options="{
        chunk: pagesOnChunk,
        chunksNavigation: 'scroll',
        edgeNavigation: true,
        texts: {
          count: '',
          first: $t('page_first'),
          last: `${$t('page_last')}: ${pageCount}`
        }
      }"
    />
    <div class="pagination-setting">
      <Input
        class="pagination-setting__page"
        :value="currentPage"
        @input="currentPageChangeDebounce"
        :label="$t('page_go_to')"
        :min="1"
        :max="pageCount"
        type="number"
      />
      <Select
        class="pagination-setting__limit"
        :value="currentLimit"
        @input="currentLimitChange"
        :options="limitOptions"
        id="limit"
        :label="$t('page_record_number')"
        no-empty-value
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import VuePagination2 from 'vue-pagination-2'
import Input from '@/components/form/inputs/Input'
import Select from '@/components/form/select/Select'

export default {
  name: 'Pagination2',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    currentLimit: {
      type: Number,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    defaultLimit: {
      type: Number,
      default: 25
    },
    pageChangeHandler: {
      type: Function,
      required: true
    },
    limitChangeHandler: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Select,
    Input,
    VuePagination2
  },
  data () {
    return {
      pagesOnChunk: 10
    }
  },
  computed: {
    limitOptions () {
      const options = [10, 25, 50, 100]
      if (!options.includes(this.defaultLimit)) {
        options.push(this.defaultLimit)
        options.sort((a, b) => a - b)
      }
      return options.map(limit => ({ id: limit, title: limit }))
    }
  },
  methods: {
    currentPageChangeDebounce: debounce(function (page) {
      this.currentPageChange(+page)
    }, 500),
    currentPageChange (page) {
      if (page >= 1 && page <= this.pageCount) {
        this.pageChangeHandler(page)
      }
    },
    currentLimitChange (limit) {
      this.limitChangeHandler(+limit)
    },
    onResize () {
      const windowWidth = window.innerWidth
      const min = 3
      const max = 10
      this.pagesOnChunk = Math.floor(windowWidth / 100)
      this.pagesOnChunk = Math.max(this.pagesOnChunk, min)
      this.pagesOnChunk = Math.min(this.pagesOnChunk, max)
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style lang="scss">
.VuePagination__pagination-item {
  padding-left: rem(5px);
  padding-right: rem(5px);
  .page-link {
    border-radius: rem(6px);
    white-space: nowrap;
  }
}
.pagination-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: end;
  @include bp(9) {
    display: grid;
    grid-template-columns: auto max-content;
  }
  .pagination {
    font-family: "Roboto", sans-serif;
    font-size: rem(15px);
    font-weight: 400;
    color: #465674;
    justify-content: center;
    // this prevents pagination to hide when there is only 1 page
    display: flex !important;
    &.disabled .page-link {
      pointer-events: none;
    }
  }
  .pagination-setting {
    display: flex;
    flex-wrap: wrap;
    gap: rem(5px);
    &__page {
      width: rem(100px);
    }
    &__limit {
      width: rem(130px);
    }
  }
}
</style>
