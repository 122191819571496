<template>
  <app-modal
    :title="$t('modal.media_upload_header')"
    modal-size="modal-lg"
    @close="close"
  >
    <!-- Header -->
    <template slot="header">
      <button
        @click="uploadFiles"
        type="button"
        class="btn btn-green"
        data-dismiss="modal"
        :disabled="uploading"
        data-test="upload-button"
      >
        <i class="fa fa-check"></i> {{ $t('modal.done') }}
      </button>
    </template>

    <!-- Body -->
    <template slot="body">
      <div v-if="uploading" class="m-t-50 m-b-50">
        <h5 class="text-center">{{ $t('modal.uploading') }}</h5>
        <app-progressbar></app-progressbar>
      </div>
      <div v-if="uploading === false">
        <div v-for="(uploadedFile, index) in uploadedFiles" :key="`item-${index}`">
          <div class="row">
            <div class="col-3">
              <app-pdf
                :src="uploadedFile.base64"
                :page="1"
                :scale.sync="scale"
              >
              </app-pdf>
            </div>
            <div class="col-8 text-left">
              <app-textarea
                id="headline"
                v-model="uploadedFile.headline"
                :label="$t('dam.headline')"
                :rows="2"
              >
              </app-textarea>
              <app-input
                id="by_line"
                v-model="uploadedFile.byLine"
                :label="$t('dam.by_line')"
              >
              </app-input>
              <app-input
                id="keywords"
                v-model="uploadedFile.keywords"
                :label="$t('dam.keywords')"
              >
              </app-input>
              <app-select
                id="category"
                v-model="uploadedFile.category"
                :label="$t('dam.category')"
                :options="damCategories"
                track-by="id"
                no-empty-value
              >
              </app-select>
            </div>
            <div class="col-1 text-left">
              <button
                @click="removeAsset(index)"
                class="btn btn-sm btn-danger m-t-30 m-l-20"
                title="Remove"
                data-test="remove-asset"
              >
                <i class="fas fa-window-close"> </i>
              </button>
            </div>
          </div>
          <hr v-if="index+1 < uploadedFiles.length">
        </div>
      </div>
      <div class="m-b-40" v-if="loading">
        <h5 class="text-center">{{ $t('modal.reading') }}</h5>
        <div class="progress">
          <div
            class="progress-bar bg-success wow animated progress-animated"
            style="width: 65%; height:6px;"
            role="progressbar"
          >
            <span class="sr-only">60% {{ $t('dam.complete') }}</span>
          </div>
        </div>
      </div>
    </template>
  </app-modal>
</template>

<script>
import Modal from '../shared/Modal'
import Input from '../form/inputs/Input'
import Textarea from '../form/Textarea'
import Progressbar from '../Progressbar'
import DamApi from '../../api/dam'
import { SOURCE_DAM_PDF } from '@/model/ValueObject/DamUploadSources'
import NotifyService from '../../services/NotifyService'
import Config from '../../config/index'
import Select from '../form/select/Select'
import DamPdfCategoryMixin from '../../components/mixins/valueObject/DamPdfCategoryMixin'
import PdfVuer from 'pdfvuer'

export default {
  name: 'DamPdfUploadModal',
  mixins: [DamPdfCategoryMixin],
  props: {
    files: {
      type: Array
    },
    count: {
      type: Number
    }
  },
  data () {
    return {
      uploading: false,
      loading: true,
      uploadedFiles: [],
      loadingPdf: 1,
      timeoutError: false,
      uploadedAssets: [],
      scale: 'page-width'
    }
  },
  components: {
    appModal: Modal,
    appInput: Input,
    appTextarea: Textarea,
    appProgressbar: Progressbar,
    appSelect: Select,
    appPdf: PdfVuer
  },
  computed: {},
  methods: {
    close () {
      this.$emit('close')
    },
    async load () {
      this.uploadedFiles = this.files
    },
    async uploadFiles () {
      this.timeoutError = false
      this.uploading = true
      const promises = this.uploadedFiles.map(this.uploadFile)
      await Promise.all(promises)
      this.$emit('set-media', this.uploadedAssets)
      this.close()
    },
    uploadFile (file) {
      const bodyFormData = new FormData()
      bodyFormData.set('pdf', file.pdf, file.pdf.name)
      bodyFormData.set('headline', file.headline)
      bodyFormData.set('byLine', file.byLine)
      bodyFormData.set('keywords', file.keywords)
      bodyFormData.set('category', file.category)
      bodyFormData.set('uploadSource', SOURCE_DAM_PDF)
      return DamApi(Config.dam.apiUploadTimeout).post('/pdf', bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(response => {
          this.uploadedAssets.push(response.data)
        })
        .catch(error => {
          if (error.code === 'ECONNABORTED' && this.timeoutError === false) {
            this.timeoutError = true
            NotifyService.setErrorMessage(this.$t('notify.upload_timeout_error'), '', -1)
          }
          console.log(error)
        })
    },
    removeAsset (index) {
      this.uploadedFiles = this.uploadedFiles.filter((media, mediaIndex) => mediaIndex !== index)
    }
  },
  watch: {
    async uploadedFiles () {
      if (this.loadingPdf > this.count) {
        this.loading = false
      }
      this.loadingPdf++
    }
  },
  created () {
    this.load()
  }
}
</script>
